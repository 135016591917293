import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import eventsLogo from '@images/events/events-logo.png'
//import huettnLogo from '@images/events/huettn-logo.png'
import { I18nextContext } from "gatsby-plugin-react-i18next";
import { useLocation } from '@reach/router'

const StyledNavbar = styled(motion.div)`
  ${tw`absolute z-50 flex w-full h-16 lg:h-20 lg:px-7 lg:mt-7 lg:shadow-none max-w-screen-2k`}
  @media screen and (max-width: 1023px) and (min-height: 664px) {
    ${tw`h-20`};
  }
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
  transition: all 0.5 ease-in-out;
`
const Logo = styled.div`
  ${tw` absolute left-[50%] top-[31px] lg:top-full z-50`}
  transform: translate(-50%, -50%);
`
const LeftColumn = styled.div`
  ${tw`flex-1 lg:mr-[162px] lg:border-b-[1px] lg:border-b-white flex items-end lg:items-center justify-between lg:justify-start`}
`

const RightColumn = styled.div`
  ${tw`flex-1 lg:ml-[162px] lg:border-b-[1px] lg:border-b-white lg:flex justify-end items-end lg:items-center hidden`}
`

const Navbar = ({ withoutLogos = false, content }) => {
  const { thirdScreenActive } = useStateContext()
  const { language } = React.useContext(I18nextContext);
  const { pathname } = useLocation()

  const DesktopLogo = styled.img`
    ${tw`hidden lg:block`}
  `

  const disabledLinks = (['/', '/en/'].includes(pathname) && !thirdScreenActive);

  return (
    <StyledNavbar>
      <Logo>
        <Link to={language === 'de' ? '/' : '/en/'}>
          <img src={content.mobileLogo.default} alt={content.logoText} tw="lg:hidden" />
          <DesktopLogo src={content.logo.default} alt={content.logoText} />
        </Link>
      </Logo>
      <LeftColumn>
        {!withoutLogos && (
          <>
            <Link
              to={language === 'en' ? "/en/#radler" : "/#radler"}
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <img
                src={content.radler.default}
                alt={content.radlerText}
                tw="lg:mr-7 lg:pl-2.5 px-5 py-2.5 h-10 3xs:h-11 lg:h-[50px]"
              ></img>
            </Link>
            <Link
              to={language === 'en' ? "/en/#helles" : "/#helles"}
              className={`${disabledLinks && 'pointer-events-none'}`}
            >
              <img
                src={content.helles.default}
                alt={content.hellesText}
                tw=" lg:pl-2.5 lg:pr-0 px-5 py-2.5 h-10 3xs:h-11 lg:h-[50px]"
              ></img>
            </Link>
          </>
        )}
      </LeftColumn>
      <RightColumn>
        {language !== 'en' &&
        <>
          {/*<Link
            to={'/erdinger-huettn/'}
            className={`${disabledLinks && 'pointer-events-none'}`}
          >
            <img
              className="lg:mr-7 lg:pl-2.5 px-5 h-10 3xs:h-11 lg:h-[50px]"
              src={huettnLogo}
              alt="ERDINGER Hütt'n"
            />
        </Link>*/}
          <Link
            to={'/events/'}
            className={`${disabledLinks && 'pointer-events-none'}`}
          >
            <img
              className="lg:mr-7 lg:pl-2.5 px-5 h-10 3xs:h-11 lg:h-[50px]"
              src={eventsLogo}
              alt="FestlTour"
            />
          </Link>
        </>
        }
        <a href={content.igLink} target="_blank">
          <img src={content.ig.default} alt={content.igText} tw="h-7"></img>
        </a>
      </RightColumn>
    </StyledNavbar>
  )
}

export default Navbar
